import * as React from 'react'
import styled from 'styled-components'
import { navigate } from '@reach/router'
import { breakpoints, useResolution, Anchor } from '@ecommerce/shared'
import { Icon } from './Icon/Icon'

const Breadcrumb = styled.div`
  width: 100%;
  height: 63px;
  padding: 6px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: ${(props) => props.theme.colors.white};

  .Breadcrumbs__ {
    &links {
      display: flex;
      align-items: center;
      overflow: hidden;

      span {
        width: auto;
        font-size: 14px;
        color: ${({ theme }) => theme.colors.bodyText};
        padding-right: 4px;

        &.AnchorContainer {
          padding: 0;
          display: flex;

          &:last-child {
            overflow: auto;
          }
        }
      }

      button {
        position: inherit;
        width: auto;

        &:first-child {
          padding-left: 3px;
        }

        &:last-child {
          font-weight: bold;
          width: auto;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    button > span {
      padding-left: 3px;
    }

    &go_back {
      width: 73px;
      color: ${({ theme }) => theme.colors.bodyText};
      display: flex;
      align-items: center;

      svg {
        width: 26px;
        height: 17px;
      }

      span {
        width: auto;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }

  @media (${breakpoints.desktop.min}) {
    padding: 0 20px 1px 112px;
    height: 97px;

    .Breadcrumbs__ {
      padding-top: 1px;
    }
  }
`

const AnchorContainer = styled.span<{ clickable?: boolean }>`
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  button > span {
    cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  }
`

interface Item {
  name: string
  url?: string
}

interface Props {
  items: Item[]
  className?: string
  themeId?: string
}

function Breadcrumbs(props: Props) {
  const { items, className, themeId } = props
  const { isDesktop } = useResolution()
  const toDisplayName = (name: string) => (!name || name.includes('undefined') ? 'Promoción' : name)
  return (
    <Breadcrumb className={className}>
      <div className="Breadcrumbs__links">
        {items.map((item, i) => (
          <AnchorContainer className="AnchorContainer" key={item.url} clickable={!!item.url}>
            <Anchor onClick={() => (item && item.url ? navigate(item.url, { state: { themeId } }) : null)}>
              <span>{toDisplayName(item.name)}</span>
            </Anchor>
            {i !== items.length - 1 && <span>/</span>}
          </AnchorContainer>
        ))}
      </div>

      {isDesktop && (
        <Anchor className="Breadcrumbs__go_back" onClick={() => navigate(-1)}>
          <Icon iconId="arrow_left" />
          <span>Volver</span>
        </Anchor>
      )}
    </Breadcrumb>
  )
}

export default Breadcrumbs
