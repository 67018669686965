import React from 'react'
import styled from 'styled-components'
import { Icon } from '../Icon/Icon'

type Props = React.HTMLAttributes<HTMLButtonElement> & {
  isUserFavorite: boolean
  isAuth: boolean
  onAddFavorite: () => void
  onNavigateToFavorites: () => void
}

const Wrapper = styled.button`
  display: flex;
  justify-content: center;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  .heart-icon {
    width: 16px;
    height: 16px;
    margin-right: 6px;
    color: transparent;

    &.is-favorite {
      color: ${({ theme }) => theme.colors.tertiary};
    }
  }

  span {
    color: ${({ theme }) => theme.colors.primary};
    font-size: inherit;
    font-weight: bold;
  }
`

const AddFavoriteButton = ({ isAuth, isUserFavorite, onAddFavorite, onNavigateToFavorites, ...restProps }: Props) => {
  const buttonAction = isUserFavorite ? onNavigateToFavorites : onAddFavorite
  const [callToAction, buttonText] = React.useMemo(() => {
    if (!isAuth) return ['¿Quieres saber cuando esté en promoción?', 'Avísame']
    if (isUserFavorite) return ['Agregado a', 'Mis Favoritos']

    return ['Agregar a', 'Mis Favoritos']
  }, [isAuth, isUserFavorite])

  return (
    <Wrapper onClick={buttonAction} title={buttonText} type="button" {...restProps}>
      {isAuth && <Icon className={`heart-icon ${isUserFavorite && 'is-favorite'}`} iconId="heart" />}
      {`${callToAction} `}
      <span>
        &nbsp;
        {buttonText}
      </span>
    </Wrapper>
  )
}

export default AddFavoriteButton
