import React from 'react'
import styled from 'styled-components'
import { breakpoints } from '@ecommerce/shared'
import { Icon } from '../Icon/Icon'

type Props = React.HTMLAttributes<HTMLButtonElement> & {
  isAuth: boolean
  onAddNotify: () => void
}

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 13px;
  margin-top: 0;
  margin-bottom: 8px;

  .mail-icon {
    color: ${({ theme }) => theme.colors.primary};
  }

  .notify-container {
    display: flex;
    align-items: center;
  }

  span {
    color: ${({ theme }) => theme.colors.primary};
    font-size: inherit;
    font-weight: bold;
  }

  @media (${breakpoints.tabletPortrait.min}) {
    justify-content: flex-start;
    margin-top: 9px;
  }

  @media (${breakpoints.desktop.min}) {
    display: none;
  }
`

const AddNotifyButton = ({ isAuth, onAddNotify, ...restProps }: Props) => {
  const buttonAction = onAddNotify
  const [callToAction, buttonText] = React.useMemo(() => {
    return ['Notificarme', 'cuando esté disponible']
  }, [isAuth])

  return (
    <Wrapper onClick={buttonAction} title={buttonText} type="button" {...restProps}>
      <div className="notify-container">
        <Icon className="mail-icon" iconId="mail" size={22} />
        <span>{`${callToAction}`}</span>
      </div>
      &nbsp;
      {buttonText}
    </Wrapper>
  )
}

export default AddNotifyButton
