import React, { useState } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Thumbs } from 'swiper/core'
import { toCssPrefix, Image, useResolution, breakpoints } from '@ecommerce/shared'

type Props = { images: string[]; badgeText?: string }

SwiperCore.use([Thumbs])

const { cssPrefix, toPrefix } = toCssPrefix('ProductImagesSlider__')
const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  .${cssPrefix} {
    &slider {
      padding: 10px 0;
    }

    &slide {
      width: 170px;
      height: 180px;
      cursor: pointer;
      padding: 0 10px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        user-select: none;
        user-drag: none;
        object-fit: contain;
        padding: 20px;
        border-radius: ${({ theme }) => theme.borderRadius};
        box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
      }
    }

    &badge {
      width: 100%;
      height: 25px;
      position: absolute;
      bottom: 0;
      left: 0;
      font-size: 14px;
      background-color: ${({ theme }) => theme.colors.gold};
      color: ${({ theme }) => theme.colors.white};
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &thumbs {
      padding: 15px 0;
      width: 170px;
    }

    &slide-thumb {
      width: auto;
      height: 40px;
      cursor: pointer;
      opacity: 0.5;
      transition: 0.25s;

      &.is-active {
        opacity: 1;
      }

      img {
        width: 40px;
        object-fit: contain;
        padding: 2px;
        display: block;
        margin: auto;
        border-radius: ${({ theme }) => theme.borderRadius};
        box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
      }
    }
  }

  @media screen and (${breakpoints.tabletPortrait.min}) {
    .${cssPrefix} {
      &slide {
        width: 305px;
        height: 305px;
      }

      &thumbs {
        width: 215px;
      }

      &slide-thumb {
        height: 50px;

        img {
          width: 50px;
        }
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &thumbs {
        margin-top: 20px;
      }

      &slide {
        width: 390px;
        height: 435px;
      }
    }
  }
`
const Slider = styled(Swiper)``

const ProductImagesSlider = ({ images, badgeText }: Props) => {
  const [swiperThumbsInstance, setSwiperThumbsInstance] = useState<SwiperCore | null>(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const { isMobile } = useResolution()

  const slidesGap = isMobile ? 16 : 32

  if (!images || images.length <= 0) return null

  return (
    <Wrapper className={cssPrefix}>
      <Slider
        onSlideChange={({ activeIndex: i }) => setActiveIndex(i)}
        spaceBetween={15}
        thumbs={{ swiper: swiperThumbsInstance }}
        className={toPrefix('slider')}
      >
        {images.map((image, i) => (
          <SwiperSlide key={image} className={toPrefix('slide')}>
            <Image alt={`slide-${i}`} src={image} />
            {badgeText && <span className={toPrefix('badge')}>{badgeText}</span>}
          </SwiperSlide>
        ))}
      </Slider>
      <Slider
        watchSlidesVisibility
        watchSlidesProgress
        slidesPerView={3}
        spaceBetween={slidesGap}
        onSwiper={setSwiperThumbsInstance}
        className={toPrefix('thumbs')}
      >
        {images.map((image, i) => (
          <SwiperSlide
            key={image}
            className={`${toPrefix('slide-thumb')} ${activeIndex === i ? 'is-active' : 'is-inactive'}`}
          >
            <Image alt={`slide-${i}`} src={image} />
          </SwiperSlide>
        ))}
      </Slider>
    </Wrapper>
  )
}

export default ProductImagesSlider
