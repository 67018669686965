import { BlockType } from '../../graphql/contentfulTypes'

type Item = {
  sku: string
}

export function extractBlocksSkus(blocks: any[]) {
  return [
    ...new Set<string>(
      blocks
        ?.filter((block) => block.blockType === BlockType.PRODUCT)
        ?.map((block) => {
          const collection = block?.itemsCollection || block?.items

          return collection?.items && Array.isArray(collection?.items)
            ? collection.items.map((item: Item) => item.sku)
            : collection?.map((item: Item) => item.sku)
        })
        .filter(Boolean)
        .flat() ?? [],
    ),
  ]
}
