import React from 'react'
import styled from 'styled-components'
import { toCssPrefix, breakpoints } from '@ecommerce/shared'
import Video from '../Video'
import { ContentfulMLVideo } from '../../graphql/contentfulTypes'

type Props = React.HTMLAttributes<HTMLDivElement> & { video: ContentfulMLVideo }

const { cssPrefix, toPrefix } = toCssPrefix('ProductVideo__')
const Wrapper = styled.div`
  margin: 40px 0;

  .${cssPrefix} {
    &title {
      text-align: center;
      margin: 0;
      font-size: 24px;
    }

    &video {
      max-width: 375px;
      min-width: 375px;
      margin: 24px auto auto;
      display: block;
      height: 215px;
    }
  }

  @media screen and (${breakpoints.tabletPortrait.min}) {
    .${cssPrefix} {
      &video {
        max-width: 580px;
        min-width: 580px;
        height: 320px;
        margin: 0;
        border-radius: ${({ theme }) => theme.borderRadius};
      }

      &video-wrapper {
        background: ${({ theme }) => theme.colors.white};
        padding: 30px;
        margin-top: 40px;
        box-shadow: ${({ theme }) => theme.boxShadow.lvlTwo};
        border-radius: ${({ theme }) => theme.borderRadius};
      }
    }
  }
`

const ProductVideo = ({ video, className = '', ...props }: Props) => {
  return (
    <Wrapper {...props} className={`${cssPrefix} ${className}`}>
      {video?.title && <h2 className={toPrefix('title')}>{video.title}</h2>}
      <div className={toPrefix('video-wrapper')}>
        <Video
          poster={video?.videoPoster?.url}
          className={toPrefix('video')}
          preload="metadata"
          src={video.video.url}
        />
      </div>
    </Wrapper>
  )
}

export default ProductVideo
