import * as React from 'react'
import styled from 'styled-components'
import { toCssPrefix, FormattedProduct, ProductCard, breakpoints } from '@ecommerce/shared'
import { Icon } from '../Icon/Icon'

const { cssPrefix, toPrefix } = toCssPrefix('ProductDetailPromotion__')

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% + 34px);
  margin: 35px 0 0 -17px;
  box-shadow: ${({ theme }) => theme.boxShadow.lvlTwo};
  border-radius: 0;
  order: 4;

  .${cssPrefix} {
    &title {
      &-container {
        display: flex;
        padding: 14px 32px 10px 32px;
        background-color: ${({ theme }) => theme.colors.success};
        color: ${({ theme }) => theme.colors.white};
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
        min-height: 50px;
        align-items: center;
      }
      &-text {
        font-size: 16px;
        margin-left: 15px;
        font-weight: bold;
      }
    }
    &content {
      &-container {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding: 16px 17px;
      }

      &-description {
        font-size: 13px;
      }
      &-info {
        display: flex;
        align-items: center;
        &-text {
          color: ${({ theme }) => theme.colors.primary};
          font-size: 13px;
          margin-left: 8px;
          margin-bottom: -2px;
        }
      }
    }
  }

  @media screen and (${breakpoints.tabletPortrait.min}) {
    border-radius: ${({ theme }) => theme.borderRadius};
    width: 100%;
    margin-left: 0;

    .${cssPrefix} {
      &content {
        &-container {
          padding: 16px 30px;
        }
      }
    }
  }
`

interface ProductDetailPromotionProps {
  title: string
  description: string
  info?: string
  discountedProduct?: FormattedProduct
  onDiscountedProductNavigate?: (product: FormattedProduct) => void
  className?: string
}

const ProductDetailPromotion = ({
  title,
  description,
  info,
  discountedProduct,
  onDiscountedProductNavigate,
  className = '',
}: ProductDetailPromotionProps) => {
  if (!title || !description || typeof title !== `string` || typeof description !== `string`) return null

  return (
    <Wrapper className={className}>
      <div className={`${toPrefix('title-container')} ${className}-title-container`}>
        <Icon iconId="Promociones" />
        <span className={toPrefix('title-text')}>{title}</span>
      </div>
      <div className={toPrefix('content-container')}>
        <span className={toPrefix('content-description')}>{description}</span>
        {discountedProduct && (
          <ProductCard
            type="small-horizontal"
            product={discountedProduct}
            onNavigate={() => (onDiscountedProductNavigate ? onDiscountedProductNavigate(discountedProduct) : null)}
          />
        )}
        {info && (
          <div className={toPrefix('content-info')}>
            <Icon iconId="info_fill" size="15" fillColor="dark" />
            <span className={toPrefix('content-info-text')}>{info}</span>
          </div>
        )}
      </div>
    </Wrapper>
  )
}

export default ProductDetailPromotion
