import React from 'react'
import { Helmet } from 'react-helmet'
import { PageProps, navigate } from 'gatsby'
import { City, useResolution, Product, useAuth, useLocation } from '@ecommerce/shared'
import styled, { ThemeProvider, DefaultTheme } from 'styled-components'
import ProductDetail from '../components/ProductDetail'
import Layout from '../components/Layout'
import { NavBarPage } from '../components/NavBar/NavBarPage'
import withPageTransition from '../components/withPageTransition'
import { ProductViewNode } from '../graphql/productView'
import useTheme from '../hooks/useTheme'
import { useFetchProductDetail } from './utils'
import { FlatLocationProductCategory } from '../types/PgPages'

type Props = PageProps<
  {},
  {
    customProduct: Product & { slugLocation: string; city: string; currentCity: City }
    productViewData: ProductViewNode['template']
    currentCity: City
    productCategories: FlatLocationProductCategory[]
  },
  {
    themeId?: string
    originListName?: string
  }
>

const Wrapper = styled.div`
  .navbar-menu-spacer {
    margin-top: 100px;
  }
`

const ProductView = ({ pageContext, location }: Props) => {
  const locationState = location?.state
  const { customProduct: buildTimeProduct, productViewData, currentCity, productCategories } = pageContext
  const { isDesktop } = useResolution()
  const navbar = () => <NavBarPage currentCity={buildTimeProduct.currentCity} />
  const {
    state: { hasAnonymousToken },
  } = useAuth()

  const { isBolivia } = useLocation()

  const { currentTheme } = useTheme(locationState?.themeId)

  const mergeThemes = (current: DefaultTheme, extension = currentTheme) =>
    ({
      ...current,
      colors: { ...current.colors, ...(extension ? { brand: extension.brand, brandText: extension.brandText } : {}) },
    } as DefaultTheme)

  const city = currentCity

  const { product, isError, isFetching } = useFetchProductDetail({
    buildTimeProduct,
    city,
    isBolivia: isBolivia(),
    hasGuestToken: hasAnonymousToken,
    originListName: locationState?.originListName ?? 'product detail - direct navigation',
  })

  return (
    <ThemeProvider theme={mergeThemes}>
      <Wrapper>
        <Layout
          id="layout"
          title={buildTimeProduct.title}
          pathName="productView"
          currentCity={buildTimeProduct.currentCity}
          navbar={isDesktop ? undefined : navbar}
          categories={productCategories}
        >
          <Helmet>
            <title>{buildTimeProduct.title}</title>
          </Helmet>
          <ProductDetail
            location={location}
            product={product}
            isLoading={isFetching}
            isError={isError}
            templateData={productViewData}
            citySlug={city.slug}
            onDiscountedProductNavigate={(discountedProduct) => {
              navigate(`/${city.slug}/products/${discountedProduct.skuCode}`, {
                state: {
                  themeId: locationState?.themeId,
                  originListName: `discounted product - ${product.skuCode} detail`,
                },
              })
            }}
          />
        </Layout>
      </Wrapper>
    </ThemeProvider>
  )
}

export default withPageTransition(ProductView)
