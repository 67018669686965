import { useEffect, useState } from 'react'
import {
  City,
  getProductsBySkus,
  sendDataToGTM,
  toCurrencyNumber,
  log,
  getCommerlayerSku,
  Country,
  capitalize,
  slugify,
} from '@ecommerce/shared'
import useQuery, { Options } from '../../hooks/useQuery'
import { extractCLayerErrorMessagesArray } from '../../utils/errors'
import { sendMessageToSentry, ErrorLevel, ErrorSource } from '../../utils/sentry'
import { DetailViewProduct } from '../../components/ProductDetail/types'
import { RichTextJson, ContentfulMLVideo, CollapseItem } from '../../graphql/contentfulTypes'
import { QueryPromo } from '../../graphql/promoListQuery'
import secrets from '../../config/secrets'
import { Promo } from '../../components/PromoList/common'

const isBO = secrets.COUNTRY === Country.BOLIVIA

type QueryResult = {
  items: Array<{
    video?: ContentfulMLVideo
    sliderImagesCollection?: { items: Array<{ url: string }> }
    customNetContent?: RichTextJson
    labelInfoText?: RichTextJson
    customLabelSteps?: {
      title?: string
      itemsCollection: {
        items: QueryPromo[]
      }
    }
    collapseItemsCollection?: {
      items: Array<CollapseItem>
    }
    grapeWine?: string
    locationWine?: string
    pairingWine?: string
    temperatureWine?: string
  }>
}

const contentfulDefaultLocation = isBO ? 'LA PAZ' : 'ARICA'

const onPriceReady = (
  price: number,
  city: City,
  buildTimeProduct: DetailViewProduct,
  isBolivia: boolean,
  originListName: string,
) => {
  if (city && city.commerceLayer.market.number) {
    sendDataToGTM({
      event: 'productView',
      pageTitle: buildTimeProduct.title,
      title: buildTimeProduct.title,
      price: `${price}`,
      categoryName: buildTimeProduct.categoryName,
      brandName: buildTimeProduct.brandName,
      ecommerce: {
        detail: {
          actionField: { list: capitalize(originListName, true) },
          products: {
            name: buildTimeProduct.title,
            id: buildTimeProduct.skuCode,
            price: `${toCurrencyNumber(price, isBolivia)}`,
            brand: buildTimeProduct.brandName ?? null,
            category: buildTimeProduct.categoryName ?? null,
            variant: buildTimeProduct.size ?? null,
          },
        },
      },
    })
  }
}

const getProductDetailArgs = (skuCode: string, isBundle: boolean): [string, Options] => [
  `query ProductDetail($sku: String!, $location: String!) {
        ${isBundle ? 'bundleCollection' : 'productCollection'}(where: { sku: $sku, location: $location }, limit: 1) {
          items {
            video {
              videoPoster {
                url
              }
              title
              video {
                url
              }
            }
            sliderImagesCollection {
              items {
                url
              }
            }
            customNetContent {
              json
            }
            labelInfoText {
              json
            }
            customLabelSteps {
              title
              itemsCollection {
                items {
                  ... on MlCardPromo {
                    sys {
                      id
                    }
                    promoTitle
                    promoSubtitle
                    promoDescription
                    titleType
                    image {
                      url
                    }
                  }
                }
              }
            }
            collapseItemsCollection {
              items {
                title
                content {
                  json
                }
              }
            }
            grapeWine
            locationWine
            pairingWine
            temperatureWine
          }
        }
      }
    `,
  {
    variables: {
      sku: `${skuCode}`,
      location: contentfulDefaultLocation,
    },
  },
]

interface UseFetchProductDetailArgs {
  buildTimeProduct: DetailViewProduct
  city: City
  isBolivia: boolean
  hasGuestToken: boolean
  originListName: string
}

export const useFetchProductDetail = ({
  buildTimeProduct,
  city,
  isBolivia,
  hasGuestToken,
  originListName,
}: UseFetchProductDetailArgs) => {
  const { data, loading } = useQuery<{ [key in 'bundleCollection' | 'productCollection']: QueryResult }>(
    ...getProductDetailArgs(buildTimeProduct.skuCode, buildTimeProduct.isBundle ?? false),
  )

  const [product, setProduct] = useState(buildTimeProduct)
  const [isError, setIsError] = useState(false)
  const [isFetching, setIsFetching] = useState(true)

  useEffect(() => {
    const getAlgoliaPromotionData = async () => {
      const res = await getProductsBySkus(slugify(city.commerceLayer.stockLocation.name), [buildTimeProduct.skuCode])

      if (res?.length) {
        const sku = res?.[0]
        const promotion = sku?.promotion

        if (res[0].promotion?.discountSkus?.length) {
          const discountedProductRes = await getProductsBySkus(slugify(city.commerceLayer.stockLocation.name), [
            res[0].promotion.discountSkus[0],
          ])
          if (discountedProductRes?.length && promotion) promotion.discountedProduct = discountedProductRes?.[0]
        }

        setProduct((prev) => ({ ...prev, ...(sku ?? {}), promotion }))
      }
    }

    getAlgoliaPromotionData()
  }, [])

  useEffect(() => {
    if (data && !loading) {
      const result = data[buildTimeProduct.isBundle ? 'bundleCollection' : 'productCollection']?.items?.[0]
      const productVideo = result?.video
      const collapseItemsCollection = result?.collapseItemsCollection
      const sliderImages = result?.sliderImagesCollection?.items
        ? result.sliderImagesCollection.items.map((item) => item.url).filter((url) => url)
        : undefined
      const customNetContent = result?.customNetContent
      const labelInfoText = result?.labelInfoText
      const wineDetail = {
        grapeWine: result?.grapeWine,
        locationWine: result?.locationWine,
        pairingWine: result?.pairingWine,
        temperatureWine: result?.temperatureWine,
      }
      const customLabelStepsTitle = result?.customLabelSteps?.title
      const customLabelStepsItems: Promo[] =
        result?.customLabelSteps?.itemsCollection?.items?.map((promo) => ({
          id: promo?.sys?.id,
          titleType: promo?.titleType,
          title: promo?.promoTitle,
          description: promo?.promoDescription,
          image: promo?.image?.url,
          slug: promo?.actionSlugLong || promo?.promoSlug,
          subtitle: promo?.promoSubtitle,
        })) ?? []

      if (productVideo) {
        setProduct((prev) => ({
          ...prev,
          video: productVideo,
        }))
      }

      if (sliderImages) {
        setProduct((prev) => ({
          ...prev,
          sliderImages,
        }))
      }

      if (collapseItemsCollection) {
        setProduct((prev) => ({
          ...prev,
          collapseItemsCollection: {
            items: collapseItemsCollection.items,
          },
        }))
      }

      setProduct((prev) => ({
        ...prev,
        customNetContent,
        labelInfoText,
        wineDetail,
        customLabelSteps: { title: customLabelStepsTitle, items: customLabelStepsItems },
      }))
    }
  }, [data, loading])

  useEffect(() => {
    if (hasGuestToken) {
      const getProductData = async () => {
        try {
          const productDetail = await getCommerlayerSku(buildTimeProduct.skuCode)

          setProduct((prev) => {
            log.trace('onPriceLoad', { ...prev, ...productDetail, promotion: prev.promotion })
            return { ...prev, ...productDetail, promotion: prev.promotion }
          })
          onPriceReady(productDetail.price, city, { ...product, ...productDetail }, isBolivia, originListName)
        } catch (e) {
          log.error(e)

          const messages = extractCLayerErrorMessagesArray(e)

          sendMessageToSentry({
            page: `products/${buildTimeProduct.skuCode}`,
            message: `On product view fetch: \n ${messages.join(' | ')}`,
            source: ErrorSource.CLayer,
            level: ErrorLevel.Error,
            metadata: { sku: buildTimeProduct.skuCode, errors: messages },
          })
          setIsError(true)
        } finally {
          setIsFetching(false)
        }
      }

      getProductData()
    }
  }, [hasGuestToken])

  return { data, loading, product, setProduct, isError, setIsError, isFetching, setIsFetching }
}
