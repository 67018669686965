import React from 'react'
import { toCssPrefix, breakpoints } from '@ecommerce/shared'
import styled from 'styled-components'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { RichTextJson } from '../../graphql/contentfulTypes'
import Collapse from '../Collapse'

const { cssPrefix } = toCssPrefix('Collapse__')

const WrapperDescription = styled.div`
  order: 4;
  padding-right: 21px;
  border-top: 1px solid ${(props) => props.theme.colors.medium};
  border-bottom: 2px solid ${(props) => props.theme.colors.medium};
  .${cssPrefix} {
    &title {
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.25px;
      font-weight: 400;
    }
  }
`

const Description = styled.div`
  padding: 0 58px 0 0;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.06px;

  @media (${breakpoints.desktop.min}) {
    padding: 0 100px 0 0;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: -0.24px;
  }
`

interface CollapseProps {
  label: string
  content: RichTextJson
}

const ProductDescription = ({ label, content }: CollapseProps) => {
  return (
    <WrapperDescription>
      <Collapse label={label}>
        <Description>{content?.json && documentToReactComponents(content.json)}</Description>
      </Collapse>
    </WrapperDescription>
  )
}

export default ProductDescription
