import React, { useMemo, useState } from 'react'
import { navigate } from 'gatsby'
import { WindowLocation } from '@reach/router'
import styled from 'styled-components'
import loadable from '@loadable/component'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import {
  Image,
  breakpoints,
  AddProductButton,
  useShoppingCart,
  useResolution,
  InfoMessage,
  useLocation,
  PromotionLabel,
  FormattedProduct,
  Button,
  toCurrencyNumber,
  getAuth,
  capitalize,
  useFavoriteProducts,
} from '@ecommerce/shared'
import NotifyModal from './NotifyModal'
import Breadcrumbs from '../Breadcrumbs'
import { Icon } from '../Icon/Icon'
import { ProductViewNode } from '../../graphql/productView'
import { useCartStockErrorHandler } from '../../utils/errors'
import { backgroundImageStyles } from '../../utils/styles'
import ProductVideoComponent from './ProductVideo'
import ProductImagesSlider from './Slider'
import ProductDetailPromotion from './ProductDetailPromotion'
import { DetailViewProduct } from './types'
import AddFavoriteModal from './AddFavoriteModal'
import AddFavoriteButton from './AddFavoriteButton'
import { buildLabelStudioURL } from './utils'
import secrets from '../../config/secrets'
import useContentfulGlobal from '../../hooks/useContentfulGlobal'
import ProductDescription from './ProductDescription'
import AddNotifyButton from './AddNotifyButton'

const PropertyInfo = loadable(() => import('./PropertyInfo'))
const ReturnabilityInfo = loadable(() => import('./ReturnabilityInfo'))
const CustomLabelSteps = loadable(() => import('./CustomLabelSteps'))
const CustomLabelInfo = loadable(() => import('./CustomLabelInfo'))

const loadableOptions = {
  ssr: false,
}

const ModalBackground = loadable(() => import('@ecommerce/shared'), {
  resolveComponent: (components) => components.ModalBackground,
  ...loadableOptions,
})

const Wrapper = styled.div<{ backgroundImage?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: ${({ theme }) => theme.colors.productView.wrapperBackground || theme.colors.medium};
  min-height: calc(100vh - 231px);
  ${backgroundImageStyles()}

  .ProductView__breadcrumbs {
    background: none;
    max-width: 100vw;
  }

  .ProductView__inner {
    background: ${({ theme }) => theme.colors.white};
    min-width: 100%;
    max-width: 100vw;
  }

  @media (${breakpoints.tabletPortrait.min}) {
    min-height: calc(100vh - 194px);
  }
  @media (${breakpoints.desktop.min}) {
    min-height: calc(100vh - 212px);
  }
`

const ContainerProduct = styled.div<{ isBundle?: boolean; includesBox?: boolean; hasSlider?: boolean }>`
  width: 100vw;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.productView.containerBackground};
  padding: 22px 17px;
  color: ${({ theme }) => theme.colors.bodyText};
  display: flex;
  flex-direction: column;

  .ProductView__ {
    &presale-info {
      margin: 0 auto 50px;
      max-width: 700px;

      &-wrapper {
        margin-top: 0;

        &-title-container {
          background-color: ${({ theme }) => theme.colors.gold};
        }
      }

      .ProductDetailPromotion__content-info-text {
        font-weight: bold;
      }
    }

    &add-favorite {
      width: 100%;
      text-align: center;
      margin: 22px auto;
      font-size: 16px;
      order: 1;
    }

    &add-notify {
      width: 100%;
      text-align: center;
      margin: 0 0 20px 0;
      font-size: 16px;
      order: 1;
    }

    &info {
      width: 100%;
      height: 50%;
      padding-bottom: 20px;
      display: flex;
      border-radius: ${(props) => props.theme.borderRadius};
      background-color: ${({ theme }) => theme.colors.productView.containerBackground};
      position: relative;
      overflow: hidden;
      flex-wrap: wrap;
      order: 1;

      &-custom-label-button {
        width: 150px;
        margin-top: 20px;
        font-size: 12px;
      }

      &-message {
        color: ${({ theme }) => theme.colors.error};
        svg {
          fill: ${({ theme }) => theme.colors.error};
        }
      }

      &-slider {
        overflow: hidden;
      }

      &-slider,
      &-image {
        width: 50%;
      }

      &-image {
        padding: 0;
        box-shadow: ${(props) => props.theme.boxShadow.lvlOne};
        border-radius: ${(props) => props.theme.borderRadius};
        display: flex;
        flex-direction: column;

        &-img {
          padding: 10px 18px;
          height: 180px;
          object-fit: contain;
        }
      }

      &-recyclable {
        width: 100%;
        padding: 5px 1px;
        border-radius: 0 0 2px 2px;
        background-color: ${({ theme, isBundle }) =>
          isBundle ? `${theme.colors.blackLow}` : `${theme.colors.success}`};
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto 0 0;

        &.is-presale {
          background-color: ${({ theme }) => theme.colors.gold};
        }

        &-button,
        &-text {
          font-size: ${({ includesBox }) => (includesBox ? '10' : '11')}px;
          font-weight: bold;
          color: ${(props) => props.theme.colors.white};
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        &-button {
          width: 15px;
          height: 15px;
          object-fit: contain;
        }
        &-text {
          height: 13px;
          padding-left: 5px;
        }
      }
      &-text {
        width: 49%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 17px;
        padding-top: 10px;
        height: ${({ hasSlider }) => (hasSlider ? `190px` : `auto`)};

        > div {
          display: flex;
          flex-direction: column;

          &:last-child {
            margin-top: -5px;
          }
        }

        &-title {
          font-size: 16px;
          font-weight: 500;
          line-height: 1.31;
          letter-spacing: -0.2px;
          text-transform: lowercase;
          max-height: 82px;
          overflow: hidden;
          text-overflow: ellipsis;
          &::first-letter {
            text-transform: uppercase;
          }
        }
        &-discount {
          padding-top: 3px;
          font-size: 11px;
          font-weight: bold;
          color: ${(props) => props.theme.colors.success};
        }
        &-rawPrice {
          margin-top: 10px;
          padding-top: 3px;
          font-size: 13px;
          text-decoration: line-through;
        }
        &-price {
          padding-top: 3px;
          font-size: 16px;
          font-weight: bold;
        }
        &-button {
          padding-top: 3px;
          width: 100%;
          height: 40px;
        }
      }
    }

    &promotion-help-text {
      width: 100%;
      white-space: unset;
    }
  }

  @media (${breakpoints.tabletPortrait.min}) {
    padding: 35px 36px;
    .ProductView__ {
      &add-favorite {
        order: 1;
        margin: 0 auto 35px auto;
      }

      &add-notify {
        display: none;
      }

      &info {
        padding-bottom: 32px;
        height: auto;

        &-custom-label-button {
          width: 200px;
          font-size: 14px;
        }

        &-image {
          &-img {
            height: 305px;
          }
        }

        &-text {
          padding: 10px 8px 0 26px;
          height: ${({ hasSlider }) => (hasSlider ? `315px` : `auto`)};

          &-title,
          &-price {
            font-size: 18px;
          }
          &-discount,
          &-rawPrice {
            padding-top: 6px;
            font-size: 14px;
          }
          &-button {
            width: 200px;
            height: 40px;
            margin-top: 32px;
          }
          &-title {
            max-height: 88px;
          }
        }
      }
      &-recyclable {
        padding: 5px;
        &-text {
          font-size: 13px;
        }
      }
    }
  }

  @media (${breakpoints.desktop.min}) {
    width: 85%;
    padding: 35px 102px;
    margin: auto;

    .ProductView__ {
      &add-product-button-wrapper {
        order: 2;
      }

      &add-favorite {
        order: 3;
        margin: 0 0 27px auto;
        min-width: calc(100% - ${({ hasSlider }) => (hasSlider ? '390px' : '50%')});
        text-align: left;
        max-width: 49%;
        padding-left: ${({ hasSlider }) => (hasSlider ? '60px' : '42px')};
        justify-content: flex-start;
      }

      &add-notify {
        display: flex;
        order: 3;
        margin: 0 0 27px auto;
        min-width: calc(100% - ${({ hasSlider }) => (hasSlider ? '390px' : '50%')});
        text-align: left;
        max-width: 49%;
        padding-left: ${({ hasSlider }) => (hasSlider ? '60px' : '42px')};
        justify-content: flex-start;
      }

      &info {
        flex-wrap: nowrap;

        &-image {
          &-img {
            height: 380px;
          }
        }

        &-slider {
          width: 390px;
          margin-right: 20px;
        }

        &-text {
          padding: 0 8px 0 40px;
          height: auto;

          &-button {
            margin-top: 12px;
          }
        }
      }

      &promotion-help-text {
        width: 100%;
        white-space: unset;
      }
    }
  }

  @media screen and (${breakpoints.desktopWide.min}) {
    .ProductView__ {
      &add-favorite {
        white-space: nowrap;
      }

      &add-notify {
        white-space: nowrap;
      }
    }
  }
`

interface Props {
  product: DetailViewProduct
  isLoading: boolean
  isError: boolean
  templateData: ProductViewNode['template']
  citySlug: string
  backgroundImage?: string
  onDiscountedProductNavigate?: (product: FormattedProduct) => void
  location: WindowLocation
}

const ProductDetail = (props: Props) => {
  const {
    product,
    isLoading,
    isError,
    templateData,
    backgroundImage,
    onDiscountedProductNavigate,
    citySlug,
    location,
  } = props

  const {
    skuCode,
    image,
    title,
    discount = 0,
    price,
    rawPrice = 0,
    hasDiscount,
    recyclable = false,
    unavailable,
    categoryName,
    brandName,
    packing,
    size,
    isBundle,
    slugLocation,
    returnabilityLabelType,
    netContent,
    video,
    sliderImages = [],
    customNetContent,
    isCustomizable,
    wineDetail,
  } = product

  const [showAddNotifyModal, setShowAddNotifyModal] = useState(false)
  const [showCustomLabelInfo, setShowCustomLabelInfo] = useState(false)
  const [showAddFavoriteModal, setShowAddFavoriteModal] = useState(false)

  const isAuth = getAuth()

  const { categoryLabel, descriptionLabel, netContentLabel, packingLabel, skuLabel } = templateData
  const propertyInfoLabels = { categoryLabel, descriptionLabel, netContentLabel, packingLabel, skuLabel }

  const {
    retornabilityLink,
    retornabilityMessage,
    requireBottleBody,
    requireBottleTitle,
    includesBottleBody,
    includesBottleTitle,
  } = templateData

  const retornabilityContent = {
    retornabilityLink,
    retornabilityMessage,
    ...(isBundle && returnabilityLabelType !== 'product'
      ? { title: includesBottleTitle, body: documentToReactComponents(includesBottleBody.json) }
      : { title: requireBottleTitle, body: documentToReactComponents(requireBottleBody.json) }),
  }

  const {
    state: { byHash },
  } = useShoppingCart()

  const { toCurrency, includesBoxProductFormats } = useLocation()
  const { isMobile, isDesktop, isTablet } = useResolution()

  const productAdded = byHash[skuCode]
  const quantity = productAdded ? productAdded.quantity : 0

  const { alcoholCategories, mainAlcoholCategorySlug, wineCategories, mainWineCategorySlug } = useContentfulGlobal()

  const categorySlug = useMemo(() => {
    if (!categoryName) return '#'

    let slug = capitalize(categoryName)

    if (alcoholCategories.includes(categoryName)) {
      slug = capitalize(mainAlcoholCategorySlug)
    }
    if (wineCategories.includes(categoryName)) {
      slug = mainWineCategorySlug
    }

    return `category/${slug}`
  }, [categoryName])

  const items = [
    {
      name: 'Inicio',
      url: `/${props.citySlug ?? slugLocation}`,
    },
    {
      name: capitalize(categoryName ?? ''),
      url: `/${props.citySlug}/${categorySlug}`,
    },
    {
      name: title,
      url: '',
    },
  ]

  const showDiscount = discount && discount > 0
  const priceTag = <span className="ProductView__info-text-price">{price ? toCurrency(price) : ''}</span>

  const { textByCountry, isBolivia } = useLocation()
  const stockErrorHandler = useCartStockErrorHandler()

  const includesBox = !!netContent && includesBoxProductFormats.includes(netContent)
  const isForPreSale = Number(product.skuCode) === secrets.JW_PRESALE_SKU

  const { isFavorite, onButtonClick: onAddFavoriteClick } = useFavoriteProducts({ sku: Number(product.skuCode) })

  const getReturnabilityLabelText = () => {
    if (isForPreSale) return 'PREVENTA'

    if (isBundle && returnabilityLabelType !== 'product') {
      const baseText = `INCLUYE ${textByCountry('ENVASE', 'BOTELLA')}`
      const includesBoxText = includesBox ? ' Y JABA' : ''
      if (!isMobile) return `${baseText} RETORNABLE${includesBoxText}`
      return `${baseText}${includesBoxText}`
    }
    const baseText = `RECUERDA TU ${textByCountry('ENVASE', 'BOTELLA')}`
    if (!isMobile) return `${baseText} RETORNABLE`
    return baseText
  }

  const params = new URLSearchParams(location.search)
  const labelUrl = params.get('labelUrl')
  const labelId = params.get('labelId')

  const onCustomizeLabel = () => {
    if (product.johnnieWalkerTag) {
      window.location.href = buildLabelStudioURL({
        productId: product.johnnieWalkerTag ?? 'jw_black',
        price: toCurrencyNumber(price, isBolivia()),
        sku: product.skuCode,
        slug: citySlug,
      })
    }
  }

  const productSliderImages = useMemo(() => {
    let contentfulImages = sliderImages ?? []
    const label = labelUrl || productAdded?.labelUrl

    if (typeof label === 'string' && label.length) contentfulImages = [label, ...contentfulImages]

    return [...new Set(contentfulImages)]
  }, [sliderImages, location.search, productAdded])
  const productIsCustomizable = isCustomizable && !labelUrl && !productAdded?.labelUrl && !!product.johnnieWalkerTag

  const onNavigateToFavorites = () => navigate(`/${citySlug}/mis-favoritos`)

  const onAddFavorite = () => {
    if (!isAuth) return setShowAddFavoriteModal(true)

    return onAddFavoriteClick()
  }

  const onAddNotify = () => {
    return setShowAddNotifyModal(true)

    // return onAddFavoriteClick()
  }

  return (
    <Wrapper backgroundImage={backgroundImage}>
      {showAddFavoriteModal && (
        <AddFavoriteModal
          onNavigateToFavorites={onNavigateToFavorites}
          onCancel={() => setShowAddFavoriteModal(false)}
          product={product}
        />
      )}
      {showAddNotifyModal && (
        <ModalBackground zIndex="10">
          <NotifyModal product={product} onCancel={() => setShowAddNotifyModal(false)} />
        </ModalBackground>
      )}
      {showCustomLabelInfo && product?.labelInfoText && (
        <CustomLabelInfo
          content={product?.labelInfoText}
          onCancel={() => setShowCustomLabelInfo(false)}
          onConfirm={onCustomizeLabel}
        />
      )}
      <div className="ProductView__inner">
        <Breadcrumbs className="ProductView__breadcrumbs" items={items} />
        {product?.customLabelSteps?.items?.length ? (
          <CustomLabelSteps items={product?.customLabelSteps?.items ?? []} title={product?.customLabelSteps?.title} />
        ) : null}
        <ContainerProduct
          hasSlider={productSliderImages && productSliderImages.length > 0}
          isBundle={isBundle && returnabilityLabelType !== 'product'}
          includesBox={includesBox}
        >
          {isForPreSale && (
            <div className="ProductView__presale-info">
              <ProductDetailPromotion
                className="ProductView__presale-info-wrapper"
                title="Producto en preventa"
                description="Este producto es parte de una edición limitada de Johnnie Walker y se encuentra en preventa."
                info="El despacho se realizará a partir del 03/12/21."
              />
            </div>
          )}
          <div className="ProductView__info">
            {productSliderImages && productSliderImages.length > 0 ? (
              <div className="ProductView__info-slider">
                <ProductImagesSlider badgeText={isForPreSale ? 'PREVENTA' : undefined} images={productSliderImages} />
              </div>
            ) : (
              <div className="ProductView__info-image">
                {image ? (
                  <>
                    {product?.promotion?.catalogDetail && product?.promotion?.catalogDetail.length > 0 && (
                      <PromotionLabel product={product} promotion={product?.promotion} />
                    )}
                    <Image className="ProductView__info-image-img" src={image} alt="image-detail" />
                  </>
                ) : (
                  <div style={{ width: '100%', height: '100%' }} className="ProductView__info-image-img" />
                )}
                {recyclable || isForPreSale ? (
                  <div className={`ProductView__info-recyclable ${isForPreSale ? 'is-presale' : ''}`}>
                    {!isForPreSale && <Icon className="ProductView__info-recyclable-button" iconId="info_outline" />}
                    <span className="ProductView__info-recyclable-text">{getReturnabilityLabelText()}</span>
                  </div>
                ) : null}
              </div>
            )}

            <div className="ProductView__info-text">
              <div>
                <span className="ProductView__info-text-title">{title}</span>
                <span className="ProductView__info-text-discount">{`${showDiscount ? `${discount} % Dcto` : ''}`}</span>
                {isTablet && unavailable && <AddNotifyButton onAddNotify={onAddNotify} isAuth={isAuth} />}
              </div>

              {isDesktop && (
                <PropertyInfo
                  {...propertyInfoLabels}
                  skuCode={skuCode}
                  category={categoryName}
                  packing={packing}
                  size={size}
                  customNetContent={customNetContent}
                  wineDetail={wineDetail}
                />
              )}
              <div className="ProductView__add-product-button-wrapper">
                <span className="ProductView__info-text-rawPrice">{`${hasDiscount ? toCurrency(rawPrice) : ''}`}</span>
                {priceTag}
                {productIsCustomizable ? (
                  <Button
                    onClick={() => setShowCustomLabelInfo(true)}
                    title="Personalizar etiqueta"
                    className="ProductView__info-custom-label-button"
                  >
                    Personalizar etiqueta
                  </Button>
                ) : (
                  <AddProductButton
                    product={{
                      skuCode,
                      image,
                      title,
                      price,
                      rawPrice,
                      discount,
                      recyclable,
                      isBundle,
                      unavailable: isError || unavailable,
                      hasDiscount,
                      size,
                      brandName,
                      categoryName,
                      labelId,
                      labelUrl,
                      presale: isForPreSale,
                      slugLocation,
                    }}
                    isLoading={isLoading}
                    className="ProductView__info-text-button"
                    quantity={quantity}
                    stockErrorHandler={stockErrorHandler}
                    disabled={!!(labelUrl || productAdded?.labelUrl || isForPreSale) && quantity === 1}
                    promotionActivationQuantity={product?.promotion?.activationQuantity}
                    promotionHelpTextProps={{ align: 'left', className: 'ProductView__promotion-help-text' }}
                  />
                )}
                {isError && (
                  <InfoMessage
                    className="ProductView__info-message"
                    message="Error de conexión. Por favor intenta más tarde."
                  />
                )}
              </div>
            </div>
          </div>
          {recyclable ? (
            <ReturnabilityInfo
              {...retornabilityContent}
              isBundle={isBundle ? returnabilityLabelType !== 'product' : false}
            />
          ) : null}
          {!isDesktop && (
            <PropertyInfo
              {...propertyInfoLabels}
              skuCode={skuCode}
              category={categoryName}
              packing={packing}
              size={size}
              customNetContent={customNetContent}
              wineDetail={wineDetail}
            />
          )}

          {product.collapseItemsCollection &&
            product.collapseItemsCollection?.items?.length !== 0 &&
            product.collapseItemsCollection?.items?.map((item) => (
              <ProductDescription key={item.title} label={item.title} content={item.content} />
            ))}

          {unavailable ? (
            <AddNotifyButton onAddNotify={onAddNotify} className="ProductView__add-notify" isAuth={isAuth} />
          ) : (
            <AddFavoriteButton
              className="ProductView__add-favorite"
              isAuth={isAuth}
              isUserFavorite={isFavorite}
              onAddFavorite={onAddFavorite}
              onNavigateToFavorites={onNavigateToFavorites}
            />
          )}

          {product.promotion && !product.promotion.discountedProduct?.isDummy && (
            <ProductDetailPromotion
              {...{
                title: product.promotion.productDetailTitle,
                description: product.promotion.productDetailDescription,
                info: product.promotion.productDetailInfoText,
                discountedProduct: product.promotion.discountedProduct,
                onDiscountedProductNavigate,
              }}
            />
          )}
        </ContainerProduct>
      </div>
      {video && <ProductVideoComponent video={video} />}
    </Wrapper>
  )
}

export default ProductDetail
