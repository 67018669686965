import React, { useState } from 'react'
import * as yup from 'yup'
import {
  ModalBackground,
  toCssPrefix,
  Button,
  ProductCard,
  Product,
  breakpoints,
  getAuth,
  TextField,
  StoredCustomer,
  getStoredCity,
  saveUserFavoriteSkus,
  useAuth,
} from '@ecommerce/shared'
import styled from 'styled-components'
import { Icon } from '../Icon/Icon'
import { modalMountAnimation } from './utils'

type Props = { product: Product; onCancel?: () => void; onNavigateToFavorites: () => void }

const { cssPrefix, toPrefix } = toCssPrefix('AddFavoriteModal__')

const Wrapper = styled(ModalBackground)`
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${modalMountAnimation} 0.1s linear forwards;

  .${cssPrefix} {
    &inner {
      background-color: ${({ theme }) => theme.colors.white};
      border-radius: ${({ theme }) => theme.borderRadius};
      width: 390px;
      min-height: 330px;
      padding: 30px;
      position: relative;
    }

    &close {
      background: none;
      border: none;
      padding: 0;
      position: absolute;
      width: 24px;
      height: 24px;
      cursor: pointer;
      top: 30px;
      right: 30px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &title {
      margin: 0;
      font-weight: 700;
      font-size: 24px;
      max-width: 250px;
    }

    &card {
      margin: 30px auto;
      cursor: auto;

      .ProductCard__title {
        margin-bottom: 60px;
      }
    }

    &buttons {
      display: flex;
      flex-direction: column;
      gap: 10px;

      button {
        padding: 0 25px;
      }
    }

    &result-indicator {
      margin: 28px auto;
      font-weight: 400;
      display: flex;
      gap: 15px;

      p {
        font-size: 16px;
        margin: 0;
        width: auto;
      }

      span {
        font-size: 12px;
        color: ${({ theme }) => theme.colors.dark};
        display: block;
        width: 100%;
      }

      svg {
        width: 24px;
        height: 24px;

        &.is-success {
          fill: ${({ theme }) => theme.colors.success};
        }

        &.is-error {
          fill: ${({ theme }) => theme.colors.error};
        }
      }
    }

    &guest-form {
      margin: 30px auto;

      &-input {
        margin-bottom: 18px;
      }
    }
  }

  @media screen and (${breakpoints.tabletPortrait.min}) {
    .${cssPrefix} {
      &inner {
        min-width: 435px;
      }

      &title {
        max-width: unset;
      }

      &buttons {
        flex-direction: row;
        justify-content: center;
        gap: 30px;

        button {
          min-width: 140px;
        }
      }
    }
  }
`

const emailSchema = yup.string().email('Debes ingresar un email valido')

type FormValue = { value?: string; errorMessage?: string }

const AddFavoriteModal = ({ product, onCancel, onNavigateToFavorites }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)

  const [formValues, setFormValues] = useState<{ email?: FormValue; name?: FormValue }>()

  const isAuth = getAuth()
  const { state: authState, setFavorites } = useAuth()
  const currentCity = getStoredCity()

  const canSubmit = !isAuth
    ? !!formValues?.email?.value && !formValues?.email?.errorMessage && !!formValues?.name?.value
    : true

  const validateEmail = async () => {
    try {
      await emailSchema.validate(formValues?.email?.value)

      return true
    } catch (error) {
      setFormValues((prev) => ({ ...prev, email: { ...(prev?.email ?? {}), errorMessage: error?.message } }))

      return false
    }
  }

  const getPayload = (): StoredCustomer => {
    const customerName = isAuth ? `${authState.firstName} ${authState.lastName}` : formValues?.name?.value
    const customerEmail = isAuth ? authState.email : formValues?.email?.value
    const clayerId = authState.ownerId.length > 0 ? authState.ownerId : undefined

    if (!currentCity?.slug || !customerName || !customerEmail) {
      throw new Error('city, customerName and customerEmail are required')
    }

    return {
      clayerId,
      slugLocation: currentCity.slug,
      name: customerName,
      email: customerEmail,
      favoriteSkus: [Number(product.skuCode)],
    }
  }

  const onAdd = async () => {
    try {
      if (!canSubmit) return
      if (isSuccess) {
        if (!isAuth) return
        return onNavigateToFavorites()
      }

      if (!isAuth) {
        const emailIsValid = await validateEmail()

        if (!emailIsValid) return
      }

      setIsLoading(true)
      setIsSuccess(false)
      setIsError(false)

      await saveUserFavoriteSkus(getPayload())

      if (isAuth) {
        setFavorites([...new Set([...(authState?.favoriteSkus ?? []), Number(product.skuCode)])])
      }

      setIsSuccess(true)
    } catch (error) {
      setIsSuccess(false)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const [resultHeading, resultText] = React.useMemo(() => {
    if (isSuccess) {
      if (!isAuth) return ['Ya tenemos tus datos', 'Te enviaremos un correo cuando este producto esté en promoción']

      return ['¡Agregado a Mis Favoritos!', 'Te enviaremos un correo cuando esté en promoción']
    }

    if (isError) {
      return ['Ha habido un error agregando el producto', 'Por favor intenta de nuevo']
    }

    return [null, null]
  }, [isSuccess, isAuth, isError])

  const renderSubmitButton = () => {
    if (isSuccess && !isAuth) return null

    let text = 'OK'
    if (isAuth) {
      if (isSuccess) text = 'Ir a Mis Favoritos'
      else text = 'Agregar'
    }

    return (
      <Button onClick={onAdd} isLoading={isLoading} isDisabled={isLoading || !product || !canSubmit}>
        {text}
      </Button>
    )
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name: inputName, value } = e.currentTarget

    setFormValues((prev) => ({ ...(prev ?? {}), [inputName]: { value } }))
  }

  return (
    <Wrapper zIndex="10" className={cssPrefix}>
      <div className={toPrefix('inner')}>
        <button
          onClick={() => (onCancel ? onCancel() : null)}
          disabled={isLoading}
          type="button"
          title="Cerrar"
          className={toPrefix('close')}
        >
          <Icon iconId="close" />
        </button>
        <p className={toPrefix('title')}>{isAuth ? `Agregar a Mis Favoritos` : 'Avísame cuando esté en promoción'}</p>
        {isSuccess || isError ? (
          <div className={toPrefix('result-indicator')}>
            <div>
              <Icon
                className={`is-${isSuccess ? 'success' : 'error'}`}
                iconId={isSuccess ? 'success' : 'error_outline'}
              />
            </div>
            <div>
              <p>{resultHeading}</p>
              <span>{resultText}</span>
            </div>
          </div>
        ) : null}
        {isAuth ? (
          <>
            {product && (
              <ProductCard
                showAddButton={false}
                className={toPrefix('card')}
                type="small-horizontal"
                product={{ ...(product ?? {}), unavailable: isLoading }}
              />
            )}
          </>
        ) : (
          <div className={toPrefix('guest-form')}>
            <TextField
              onChange={onInputChange}
              className={toPrefix('guest-form-input')}
              placeholder="Nombre"
              name="name"
              disabled={isSuccess}
            />
            <TextField
              onChange={onInputChange}
              className={toPrefix('guest-form-input')}
              placeholder="Correo electrónico"
              name="email"
              errorMessage={formValues?.email?.errorMessage}
              status={formValues?.email?.errorMessage ? 'error' : undefined}
              disabled={isSuccess}
              autoComplete="nope"
              autoCapitalize="nope"
            />
          </div>
        )}
        <div className={toPrefix('buttons')}>
          <Button
            onClick={() => (onCancel && !isLoading ? onCancel() : null)}
            isDisabled={isLoading}
            btnType={!isAuth && isSuccess ? 'primary' : 'secondary'}
          >
            {isSuccess ? 'Cerrar' : 'Cancelar'}
          </Button>
          {renderSubmitButton()}
        </div>
      </div>
    </Wrapper>
  )
}

export default AddFavoriteModal
